import { FieldProps } from "formik";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

export const Input = ({
  label,
  placeholder,
  type,
  isRequired,
  field,
  form: { touched, errors },
  ...props
}: FieldProps & {
  label: string;
  placeholder: string;
  type: string;
  isRequired?: boolean;
}) => {
  const showError = useMemo(() => {
    return touched[field.name] && errors[field.name];
  }, [errors, field.name, touched]);

  return (
    <div
      className={classNames("flex flex-col mb-1", {
        "text-red-600": showError,
      })}
    >
      <div className="flex flex-row justify-between items-center">
        <label className="text-sm font-light">{label}</label>
        {isRequired && (
          <FontAwesomeIcon icon={faAsterisk} className="w-4 h-4" />
        )}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        className={classNames(
          "appearance-none min-h-[2.3rem] bg-transparent border-b text-lg py-1 text-left rounded-none focus:ring-0 focus:shadow-none focus:outline-none",
          {
            "border-b-red-600": showError,
            "border-b-black": !showError,
          }
        )}
        {...field}
      />
      <div className="min-h-[20px] text-sm">
        {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name] as string}</div>
        )}
      </div>
    </div>
  );
};
