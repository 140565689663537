import { Field, Form, Formik } from "formik";
import Input from "./components/Input";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const SELECTED_MODEL_QUERY_KEY = "selectedModel";
const SELECTED_LEVEL_QUERY_KEY = "selectedLevel";
const SELECTED_COLOR_QUERY_KEY = "selectedColor";

const LOCAL_STORAGE_KEY = "mg-test-rides-form-submitted";
const LOCAL_STORAGE_VALUE_TRUE = "true";

const validateName = (value: string) => {
  let error;
  if (!value) {
    error = "Meno a priezvisko sú povinné.";
  }
  return error;
};

const validateEmail = (value: string) => {
  let error;
  if (!value) {
    error = "Email je povinný.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Email nemá správny formát.";
  }
  return error;
};

const validatePhoneNumber = (value: string) => {
  let error;
  if (!value) {
    error = "Telefónne číslo je povinné.";
  }
  return error;
};

const App = () => {
  const [formSubmitted, setFormSubmitted] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) === LOCAL_STORAGE_VALUE_TRUE
  );

  const saveTestRide = async (
    name: string,
    email: string,
    phoneNumber: string,
    date?: string,
    time?: string
  ) => {
    try {
      const query = new URLSearchParams(window.location.search);
      const selectedModel = query.get(SELECTED_MODEL_QUERY_KEY);
      const selectedLevel = query.get(SELECTED_LEVEL_QUERY_KEY);
      const selectedColor = query.get(SELECTED_COLOR_QUERY_KEY);

      await fetch(process.env.REACT_APP_GOOGLE_SCRIPT_URL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          date,
          time,
          selectedModel,
          selectedLevel,
          selectedColor,
        }),
      });
      setFormSubmitted(true);
      localStorage.setItem(LOCAL_STORAGE_KEY, LOCAL_STORAGE_VALUE_TRUE);
    } catch (error) {
      toast.error("Pri odoslaní nastala chyba.");
    }
  };

  return (
    <div className="h-full w-full p-4 xs:p-6 sm:p-8 bg-gradient-to-b from-transparent to-gray-300 overflow-auto">
      {!formSubmitted && (
        <>
          <div className="mb-4 xs:mb-6 sm:mb-8">
            <img
              className="w-10 h-10 xs:h-12 xs:w-12 sm:h-20 sm:w-20"
              src="/logo-mg-whitebg.svg"
              alt="MG Logo"
            />
          </div>

          <div className="text-xl font-semibold mb-4 xs:mb-6 sm:mb-8">
            Dohodnúť si testovaciu jazdu
          </div>

          <Formik
            initialValues={{
              name: "",
              email: "",
              phoneNumber: "+421",
              date: "",
              time: "",
            }}
            onSubmit={(values, actions) => {
              saveTestRide(
                values.name,
                values.email,
                values.phoneNumber,
                values.date,
                values.time
              ).then(() => actions.setSubmitting(false));
            }}
          >
            {(props) => (
              <Form>
                <Field
                  type="text"
                  name="name"
                  label="Meno a priezvisko"
                  placeholder="Jozef Príkladný"
                  isRequired
                  component={Input}
                  validate={validateName}
                />
                <Field
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="jozef@prikladny.sk"
                  isRequired
                  component={Input}
                  validate={validateEmail}
                />
                <Field
                  type="tel"
                  name="phoneNumber"
                  label="Telefónne číslo"
                  placeholder="+421911111111"
                  isRequired
                  component={Input}
                  validate={validatePhoneNumber}
                />
                <Field
                  type="date"
                  name="date"
                  label="Preferovaný dátum"
                  component={Input}
                />
                <Field
                  type="time"
                  name="time"
                  label="Preferovaný čas"
                  component={Input}
                />

                <div className="text-xs mb-4">
                  <span>
                    Návštevník webu odoslaním formulára potvrdzuje, že bol
                    oboznámený s{" "}
                  </span>
                  <a
                    href="https://www.mgmotor-slovakia.sk/informacne-oznamenie/"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Informačným oznámením
                  </a>
                  <span> o spracovaní osobných údajov.</span>
                </div>

                <button
                  type="submit"
                  className="bg-black text-white rounded-full py-2 px-4 self-start disabled:opacity-40"
                  disabled={
                    !props.dirty || !props.isValid || props.isSubmitting
                  }
                >
                  <div className="flex flex-row items-center gap-1">
                    <span>Odoslať</span>
                    <FontAwesomeIcon
                      icon={faLocationArrow}
                      className="w-3 h-3"
                    />
                  </div>
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}

      {formSubmitted && (
        <div className="h-full w-full flex flex-col justify-center items-center gap-8">
          <img
            className="w-20 h-20 xs:h-24 xs:w-24 sm:h-28 sm:w-28"
            src="/logo-mg-whitebg.svg"
            alt="MG Logo"
          />
          <div className="text-xl xs:text-2xl sm:text-3xl text-center">
            <div>Ďakujeme za vyplnenie formulára.</div>
            <div>Budeme Vás v dohľadnom čase kontaktovať.</div>
          </div>
        </div>
      )}

      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            padding: "16px",
            color: "white",
            backgroundColor: "black",
          },
          iconTheme: {
            primary: "white",
            secondary: "black",
          },
          duration: 5000,
        }}
      />
    </div>
  );
};

export default App;
